import $http from '../services/AxiosInstance';
import { saveAs } from 'file-saver';

interface Params {
  page?: number;
}

interface DownloadParams {
  clientId: number;
  policyId: number;
  documentPath?: string;
  policyNumberId?: number;
}

class OperationsRepository {
  async getOperations(params: Params) {
    try {
      const response = await $http.get(`/clients/policies?page=${params.page}`);
      return response;
    } catch (errors: any) {
      return errors;
    }
  }
  async createClientInChubb(clientId: number) {
    try {
      const response = await $http.post(
        `/clients/createChubbPerson/${clientId}`,
      );
      return response;
    } catch (errors: any) {
      return errors;
    }
  }
  async downloadClientDocument(params: DownloadParams) {
    const { clientId, policyId, documentPath } = params;

    try {
      const { data } = await $http.get(
        `/images/download/${clientId}/${policyId}/document/document-client`,
        {
          responseType: 'blob',
        },
      );

      const blob = new Blob([data]);

      saveAs(blob, `doc-${policyId}.${documentPath?.split('.')[1]}`);
      return 'ok';
    } catch (errors: any) {
      return null;
    }
  }
  async quotePolicy(policyId: number) {
    try {
      const { data } = await $http.post(`clients/policies/${policyId}/quote`);
      return data;
    } catch (errors: any) {
      throw new Error();
    }
  }
  async getPaymentURL(policyId: number) {
    try {
      const { data } = await $http.get(
        `clients/policies/${policyId}/paymentURL`,
      );
      return data;
    } catch (errors: any) {
      throw new Error();
    }
  }
  async createIssuePolicy(policyId: number) {
    try {
      const { data } = await $http.post(`clients/policies/${policyId}/issue`);
      return data;
    } catch (errors: any) {
      throw new Error();
    }
  }
  async getPDFPolicy(policyId: number) {
    try {
      const { data } = await $http.get(`clients/policies/${policyId}/chubbpdf`);
      return data;
    } catch (errors: any) {
      throw new Error();
    }
  }
  async downloadPDFPolicy(params: DownloadParams) {
    const { clientId, policyId, policyNumberId } = params;

    try {
      const { data } = await $http.get(
        `/images/download/${clientId}/${policyId}/document/chubb-document`,
        {
          responseType: 'blob',
        },
      );

      const blob = new Blob([data]);

      saveAs(blob, `${policyNumberId}.pdf`);
    } catch (errors: any) {
      throw new Error();
    }
  }
  async solvedManually(policyId: number) {
    try {
      const { data } = await $http.get(
        `/clients/policies/${policyId}/manuallySolved`,
      );

      return data;
    } catch (errors: any) {
      throw new Error();
    }
  }
  async sendPDFtoClient(policyId: number) {
    try {
      const { data } = await $http.get(
        `/clients/policies/${policyId}/sendChubbpdf`,
      );

      return data;
    } catch (errors: any) {
      throw new Error();
    }
  }

  async getDateLastSendPolicyToClient(policyId: number){{
    try {
      const { data } = await $http.get(
        `/clients/date-email-to-client/${policyId}`,
      );
      return data;
    } catch (errors: any) {
      throw new Error();
    }
  }}
}

export default OperationsRepository;
